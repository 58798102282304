<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : !disabled ? '修改' : '查看'"
    :close-on-click-modal="false"
    :visible.sync="visible"
  >
    <el-form
      ref="dataForm"
      :model="dataForm"
      :rules="dataRule"
      label-width="80px"
      @keyup.enter.native="dataFormSubmit()"
    >
      <el-form-item label="用户ID" prop="userId">
        <el-input
          v-model="dataForm.userId"
          :disabled="true"
          placeholder="用户ID"
        />
      </el-form-item>
      <el-form-item label="用户昵称" prop="userId">
        <el-input
          v-model="dataForm.nickname"
          :disabled="disabled"
          placeholder="用户昵称"
        />
      </el-form-item>
      <el-form-item label="购买者ID" prop="buyerId">
        <el-input
          v-model="dataForm.buyerId"
          :disabled="true"
          placeholder="购买者ID"
        />
      </el-form-item>
      <el-form-item label="买家昵称" prop="buyerId">
        <el-input
          v-model="dataForm.buyerNickname"
          :disabled="disabled"
          placeholder="买家昵称"
        />
      </el-form-item>
      <el-form-item label="订单ID" prop="orderId">
        <el-input
          v-model="dataForm.orderId"
          :disabled="disabled"
          placeholder="订单ID"
        />
      </el-form-item>
      <el-form-item label="提成类型" prop="commissionType">
        <el-radio-group v-model="dataForm.commissionType" :disabled="true">
          <el-radio :label="1"> 一级分销 </el-radio>
          <el-radio :label="2"> 二级分销 </el-radio>
          <el-radio :label="3"> 一级推广 </el-radio>
          <el-radio :label="4"> 二级推广 </el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="结算收益" prop="income">
        <el-input
          v-model="dataForm.income"
          :disabled="disabled"
          placeholder="结算收益"
        />
      </el-form-item>
      <el-form-item label="结算时间" prop="incomeTime">
        <el-input
          v-model="dataForm.incomeTime"
          :disabled="disabled"
          placeholder="结算时间"
        />
      </el-form-item>
      <el-form-item label="商品ID" prop="goodsId">
        <el-input
          v-model="dataForm.goodsId"
          :disabled="true"
          placeholder="商品ID"
        />
      </el-form-item>
      <el-form-item label="SKU_ID" prop="skuId">
        <el-input
          v-model="dataForm.skuId"
          :disabled="true"
          placeholder="SKU_ID"
        />
      </el-form-item>
      <el-form-item label="提成比例" prop="commission">
        <el-input
          v-model="dataForm.commission"
          :disabled="true"
          placeholder="提成比例"
        />
      </el-form-item>
      <el-form-item label="是否到账" prop="isReceive">
        <el-radio-group v-model="dataForm.isReceive" :disabled="disabled">
          <el-radio :label="0"> 未到账 </el-radio>
          <el-radio :label="1"> 已到账 </el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button v-if="!disabled" type="primary" @click="dataFormSubmit()"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      disabled: false,
      visible: false,
      dataForm: {
        id: 0,
        userId: '',
        nickname: '',
        buyerId: '',
        buyerNickname: '',
        orderId: '',
        commissionType: '',
        income: '',
        incomeTime: '',
        goodsId: '',
        skuId: '',
        commission: '',
        isReceive: '',
      },
      dataRule: {
        name: [{ required: true, message: '名称不能为空', trigger: 'blur' }],
      },
    };
  },
  methods: {
    init(id, disabled) {
      this.disabled = disabled;
      this.dataForm.id = id || '';
      this.visible = true;
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields();
        if (this.dataForm.id) {
          this.$http({
            url: `/mall/distcommission/info/${this.dataForm.id}`,
            method: 'get',
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.dataForm = data.distcommission;
            }
          });
        }
      });
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.$http({
            url: `/mall/distcommission/${
              !this.dataForm.id ? 'save' : 'update'
            }`,
            method: 'post',
            data: this.dataForm,
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
              });
              this.visible = false;
              this.$emit('refreshDataList');
            }
          });
        }
      });
    },
  },
};
</script>
